exports.components = {
  "component---src-pages-aiheet-index-js": () => import("./../../../src/pages/aiheet/index.js" /* webpackChunkName: "component---src-pages-aiheet-index-js" */),
  "component---src-pages-arkisto-index-js": () => import("./../../../src/pages/arkisto/index.js" /* webpackChunkName: "component---src-pages-arkisto-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tilaus-index-js": () => import("./../../../src/pages/tilaus/index.js" /* webpackChunkName: "component---src-pages-tilaus-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-issue-js": () => import("./../../../src/templates/issue.js" /* webpackChunkName: "component---src-templates-issue-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

